export class EncriptDecript{

     encrypt(value: string): string {
        return btoa(encodeURIComponent(value));
    }

    decrypt(value: string): string {
        return decodeURIComponent(atob(value));
    }
}
