export const locale = {
    lang: 'tr',
    data: {
        'NAV': {
            'APPLICATIONS': 'User Menu',
            'Master'  : 'Master',
            'Vendor': 'Vendor',
            'Admin': 'Admin',
            'Dashboard': 'Dashboard',
            'About': 'About',
            'Reports': 'Reports',
            'Inbound'  : 'Inbound',
            'Setup': 'Setup',
            'Internal': 'Internal',
            'Outbound Admin': 'Outbound Admin',
            'Pick Priority': 'Pick Priority',
            'DASHBOARDS': 'Dashboards',
            'Outbound': 'Outbound',
            'Outbound Dashboard': 'Outbound Dashboard',
            'Manifest': 'Manifest',
            'CALENDAR'    : 'Takvim',
            'ECOMMERCE'   : 'E-Ticaret',
            'ACADEMY'     : 'Akademi',
            'MAIL'        : {
                'TITLE': 'Posta',
                'BADGE': '15'
            },
            'MAIL_NGRX'        : {
                'TITLE': 'Posta Ngrx',
                'BADGE': '13'
            },
            'CHAT'        : 'Sohbet',
            'FILE_MANAGER': 'Dosya Yöneticisi',
            'CONTACTS'    : 'Kişiler',
            'TODO'        : 'Yapılacaklar',
            'SCRUMBOARD'  : 'Proje'
        }
    }
};
