import {Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SessionManageMent} from "../../../../@pristinebase/Process/SessionManageMent";
import {WebApiHttp} from "../../../../@pristinebase/Process/WebApiHttp.services";
import {EncriptDecript} from "../../../../@pristinebase/Process/EncriptDecript";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {PristineConfirmDialogInputComponent} from "../../../../@pristinebase/components/confirm-dialog-input/confirm-dialog-input.component";
import {PromiseResponse} from "../../../Model/PromiseResponse";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ValidateResponse} from "../../../../@pristinebase/Process/ValidateResponse";
import {Login2Service} from "../../pages/authentication/login-2/login-2.service";
import {PristineNavigationService} from "../../../../@pristinebase/components/navigation/navigation.service";
import {SignalR} from "../../../../@pristinebase/Process/signalr/SignalR";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {MatBottomSheet} from "@angular/material/bottom-sheet";


@Component({
  selector: 'app-ticket-summary-mail',
  templateUrl: './ticket-summary-mail.component.html',
  styleUrls: ['./ticket-summary-mail.component.scss']
})
export class TicketSummaryMailComponent implements OnInit {

  username: string = '';
  password: string = '';

  expandMore:boolean=false
  Getdata:any
  ticket_id:any
  vendor_query_subject:any
  previous_vendor_query:any
  vendor_query:any
  ImageArray:Array<any>=[]
  pageNo: number = 0;
  pageSize : number = 0;
  totalCount : number = 0
  ticketSummaryArray:Array<any>=[]
  TicketArray:Array<any>=[]
  TicketAttachmentArray:Array<any>=[]

  vendor_no:any
  reply_on_ticket_summary_id:any
  message_detail:any
  message_by:any
  is_admin:any
  //@ViewChild('grnpackingDialog', {static: false}) grnpackingDialog: TemplateRef<any>;
  @ViewChild('viewAfterUploadImage', {static: false}) UplaodImage:TemplateRef<any>
  @ViewChild('viewImageOfSummary', {static: false}) viewImageOfSummary:TemplateRef<any>
  @ViewChild('viewTicketAttachment', {static: false}) viewTicketAttachment:TemplateRef<any>
  @ViewChild('EditMessage', {static: false}) EditMessageDialog: TemplateRef<any>;
  @ViewChild('ReplyMessage', {static: false}) ReplyMessageDialog: TemplateRef<any>;

  constructor( public BottomSheet: MatBottomSheet,
               public sessionManageMent: SessionManageMent,
               public webApiHttp: WebApiHttp,
               private _encriptDecript: EncriptDecript,
               private _dialog: MatDialog,
               private _router: ActivatedRoute,
               private _route: Router,
               private _toster: ToastrService,
               private spinner: NgxSpinnerService,
               private _encryptdecrypt: EncriptDecript, private sanitizer: DomSanitizer,
               private _validateResponse: ValidateResponse,
               private _formBuilder: FormBuilder,
               @Inject(MAT_DATE_LOCALE) private _locale: string,

  ) {
    this.ticket_id = this._router.snapshot.paramMap.get('ticket_id');
    console.log(this.ticket_id,'Ticket Id')
    this.is_admin=this.sessionManageMent.getIsAdmin
    this.message_detail=''
  }

  ngOnInit() {
    if( this.sessionManageMent.getEmail=='' )
    {
      this._route.navigateByUrl('/pages/auth/login-2');
    }
    else
    {
      this.getSummary();
    }
  }


  getSummary()
  {
    this.pageSize=this.pageSize+50
    this.spinner.show()
    let json =
      {
        ticket_no: this.ticket_id,
        message:'',
        rowsPerPage: this.pageSize,
        pageNumber: this.pageNo
      }
    this.webApiHttp.Post(this.webApiHttp.ApiURLArray.ticketQuerySummary,json).then(res=>{
      if(res.length > 0 && res[0].condition.toLowerCase() == 'true')
      {
        this.TicketArray = res
        this.ticketSummaryArray = res[0].ticket_summary
        this.vendor_query_subject = res[0].vendor_query_subject
        this.vendor_query = res[0].vendor_query
        this.vendor_no = res[0].vendor_no
        this.previous_vendor_query = res[0].previous_vendor_query
        this.TicketAttachmentArray = res[0].files
        if((this.TicketArray[0].is_reopen.toString()=='0' && this.TicketArray[0].is_closed.toString()=='1') )
        {
          this.expandMore= true
        }
        else
        {
          this.expandMore = false
        }
      }
      else
      {
        this.ticketSummaryArray=[]
      }
    }).catch(err=>{
      console.log(err)
    }).finally(()=>{
      this.spinner.hide()
    })
  }

  fileurl:Array<any>=[]
  pic_urlArray: Array<any> = [];
  fileName: any;
  pic_url: any = ''
  urlArray: Array<any> = [];

  onFileselected(event: any) {

    // var aa=document.cr

    // let fileReader = new FileReader();
    // fileReader.readAsDataURL(event.target.files[0]);
    // fileReader.onload = (event:any) => {
    //   this.pic_url = event.target.result;
    //   this.pic_urlArray.unshift(this.pic_url);
    // }

    let read
    let files=event.target.files
    for(let i=0;i<event.target.files.length;i++){
      let file = event.target.files[i];
      ((file) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.fileurl.push(file)
          let picUrl = reader.result as string;

          this.pic_urlArray.unshift(picUrl);
        };
        // Read the file as a data URL
        reader.readAsDataURL(file);
      })(file);
      // read = new FileReader();
      // read.onload = (e)=>{
      //   this.fileurl.push(files[i])
      //   let picUrl = read.result as string;
      //   this.pic_urlArray.push(picUrl)
      // }
      // read.readAsDataURL(file);

      var ele = document.createElement('input')
      ele.setAttribute('type','file')
      ele.setAttribute('accept','')
      ele.click()
      ele.addEventListener('change',event=>{
        const file = (event.target as HTMLInputElement).files
        for(let i=0;i<file.length;i++)
        {
          const file1 = file[i]

        }
      })

    }

    console.log(this.pic_urlArray)
    this.ViewUploadImage()

  }

  filesArray: File[] = [];
  uploadFile()
  {
    var input_element: any = document.createElement('input');
    input_element.setAttribute('type', 'file');
    input_element.setAttribute('accept','.csv,.xlsx,.xls,.png,.jpg,.jpeg,.webp,.doc,.txt,.docs')
    input_element.setAttribute('multiple', '');
    input_element.click();
    input_element.addEventListener('change', event => {
      const selectedFile = (event.target as HTMLInputElement).files
      for (let i = 0; i < selectedFile.length; i++) {
        const file = selectedFile[i];
        this.filesArray.unshift(file);
      }
      this.ViewUploadImage()
    })

  }


  ViewUploadImage()
  {
    this._dialog.open(this.UplaodImage,{

    })
  }

  DeleteImage(i)
  {
    this.filesArray.splice(i,1)
  }



  InsertTicketSummary()
  {
    if(this.message_detail == null || this.message_detail =='' ||  this.message_detail ==undefined)
    {
      this._toster.error('Please Type Something in a message','Error');
      return
    }
    this.spinner.show()
    let formdata: FormData = new FormData();
    formdata.append("ticket_no", this.ticket_id)
    formdata.append("vendor_no", this.vendor_no)
    formdata.append("reply_on_ticket_summary_id",'0' ),
      formdata.append("message_detail", this.message_detail),
      formdata.append("message_by", this.vendor_no)
    formdata.append("is_admin", this.is_admin)

    for(let i=0; i<this.filesArray.length; i++)
    {
      formdata.append('imageUrl',this.filesArray[i])
    }

    this.webApiHttp.PostFormData(this.webApiHttp.ApiURLArray.InsertSummaryTicket,formdata).then(res=>{
      if(res[0].condition.toLowerCase() == 'true')
      {
        this.getSummary();
        this._toster.success(res[0].message,'Success')
        this.message_detail=''
        this.pic_urlArray=[]
        this.fileurl = []
        this.filesArray=[]
      }
      else
      {
        this._toster.error(res[0].message,'Error')
      }
    }).catch(err=>{
      console.log(err)
    }).finally(()=>{
      this.spinner.hide()
    })

  }

  SummaryImageArray:Array<any>=[]

  ViewSumamryImage(ele)
  {
    this.SummaryImageArray = ele.files
    this._dialog.open(this.viewImageOfSummary,{
      // minWidth:'300px',
      // maxWidth:'600px',
      // height:'400px'
    })
  }

  dialog_download(ele) {
    console.log(ele)
    // return
    if (ele.file_type == 'URL') {

      var a = document.createElement('a');
      a.target = '_blank';
      a.href = ele.file_url;
      a.innerText = 'File Download';
      a.click()

    } else {

      var a = document.createElement('a');
      a.target = '_blank';
      a.href = this.webApiHttp.globalurl + "/" + ele.file_url;
      a.innerText = 'File Download';
      a.click()

    }
  }

  TicketAttachment()
  {
    this._dialog.open(this.viewTicketAttachment,{

    })
  }


  closedTicket()
  {
    const dialogRef = this._dialog.open(PristineConfirmDialogInputComponent);
    dialogRef.componentInstance.confirmMessage = 'Do You Want to Closed Ticket?';
    dialogRef.componentInstance.inputFieldMessage = 'Write Remarks';
    dialogRef.componentInstance.addbutton='Confirm'
    dialogRef.afterClosed().subscribe((result: any) =>{
      if (result.condition == 'true')
      {
        this.spinner.show()
        let json =
          {
            "ticket_no": this.ticket_id,
            "vendor_no": this.vendor_no,
            "closed_remarks": result.message,
            "closed_by": this.sessionManageMent.getEmail,
            "is_admin": this.sessionManageMent.getIsAdmin
          }
        this.webApiHttp.Post(this.webApiHttp.ApiURLArray.ClosedTicket,json).then(res=>{
          if(res[0].condition.toLowerCase() == 'true')
          {
            this._toster.success(res[0].message,'Success');
            this.getSummary()
          }
          else
          {
            this._toster.error(res[0].message,'Error')
          }
        }).catch(err=>{
          console.log(err)
        }).finally(()=>{
          this.spinner.hide()
        })
      }
      else
      {
        this.spinner.hide()
      }
    })
  }

  openTicket()
  {
    const dialogRef = this._dialog.open(PristineConfirmDialogInputComponent);
    dialogRef.componentInstance.confirmMessage = 'Do You Want to Re-Open Ticket?';
    dialogRef.componentInstance.inputFieldMessage = 'Write Remarks';
    dialogRef.componentInstance.addbutton='Confirm'
    dialogRef.afterClosed().subscribe((result: PromiseResponse) =>{
      if (result.condition.toLowerCase() == 'true')
      {
        this.spinner.show()
        let json =
          {
            "ticket_no": this.Getdata,
            "vendor_no": this.vendor_no,
            "reopen_remarks": result.message,
            "reopen_by": this.sessionManageMent.getEmail,
            "is_admin": this.sessionManageMent.getIsAdmin
          }
        this.webApiHttp.Post(this.webApiHttp.ApiURLArray.reopenTicket,json).then(res=>{
          if(res[0].condition.toLowerCase() == 'true')
          {
            this._toster.success(res[0].message,'Success');
            this.getSummary()
          }
          else
          {
            this._toster.error(res[0].message,'Error')
          }
        }).catch(err=>{
          console.log(err)
        }).finally(()=>{
          this.spinner.hide()
        })
      }
      else
      {
        this.spinner.hide()
      }
    })
  }

  Expand()
  {
    this.expandMore = !this.expandMore
  }
  @ViewChild('scrollContainer',{static:false}) scrollContainer: ElementRef;

  TopButton:boolean=false

  onScroll(event: any) {
    // You can adjust the scroll threshold as needed
    this.TopButton = this.scrollContainer.nativeElement.scrollTop > -1000;
    if(this.scrollContainer.nativeElement.scrollTop > -1000)
    {
      this.TopButton=false
    }
    else
    {
      this.TopButton=true
    }
  }

  scrollToTop() {
    this.scrollContainer.nativeElement.scrollTop = 0;
  }

  isAdmin: boolean = this.sessionManageMent.getIsAdmin.toString() === '1';

  getVendorMessageAlignment(): string {
    return this.isAdmin ? 'start start' : 'end end';
  }

  getAdminMessageAlignment(): string {
    return this.isAdmin ? 'end end' : 'start start';
  }



  getSafeUrl(file: File): SafeUrl {
    const url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  editId:any
  Edit_message_detail:any
  vendor_attachment_summary_image_no:any
  diaRef:any
  getEdit(ele) : void
  {
    console.log(ele)
    this.editId=ele.id
    this.Edit_message_detail= ele.message
    this.vendor_attachment_summary_image_no=ele.vendor_attachment_summary_image_no

    this.diaRef = this._dialog.open(this.EditMessageDialog,{
      autoFocus:false,
      disableClose:true,
      position:{
        bottom:'0px'
      }
    })

    this.diaRef.afterClosed().subscribe(res=>{
    })
  }

  CloseDialogEdit() {
    this.diaRef.close()
  }



  EditTicketSummary()
  {
    if(this.Edit_message_detail == null || this.Edit_message_detail =='' || this.Edit_message_detail ==undefined)
    {
      this._toster.error('Please Type Something in a message','Error');
      return
    }
    this.spinner.show()
    let formdata: FormData = new FormData();
    formdata.append("id", this.editId)
    formdata.append("ticket_no", this.ticket_id)
    formdata.append("vendor_no", this.vendor_no)
    formdata.append("message_detail", this.Edit_message_detail)
    formdata.append("updated_by", this.sessionManageMent.getEmail)
    formdata.append("is_admin", this.is_admin)
    formdata.append("reply_on_ticket_summary_id",this.vendor_attachment_summary_image_no?this.vendor_attachment_summary_image_no:'0' )

    this.webApiHttp.PostFormData(this.webApiHttp.ApiURLArray.UpdateSummaryTicket,formdata).then(res=>{
      if(res[0].condition.toLowerCase() == 'true')
      {
        this.getSummary();
        this._toster.success(res[0].message,'Success')
        this.message_detail=''
        this.pic_urlArray=[]
        this.fileurl = []
        this.filesArray=[]
        this.diaRef.close()
      }
      else
      {
        this._toster.error(res[0].message,'Error')
      }
    }).catch(err=>{
      console.log(err)
    }).finally(()=>{
      this.spinner.hide()
    })

  }



  replyId:any
  reply_message_detail:any
  Ticket_reply_on_ticket_summary_id:any
  Reply_vendor_attachment_summary_image_no:any
  ReplyOnMessage:any
  ReplyDia:any

  replyDialog(ele) : void
  {
    console.log(ele)
    this.Ticket_reply_on_ticket_summary_id = ele.id
    this.ReplyOnMessage=ele.message

    this.ReplyDia = this._dialog.open(this.ReplyMessageDialog,{
      position: {
        bottom: '0px',
      },
    })

    this.ReplyDia.afterClosed().subscribe(res=>{
      this.reply_message_detail=''
    })

  }

  SummaryReply()
  {
    if(this.reply_message_detail == null || this.reply_message_detail =='' || this.reply_message_detail ==undefined)
    {
      this._toster.error('Please Type Something in a message','Error');
      return
    }
    this.spinner.show()
    let formdata: FormData = new FormData();
    formdata.append("ticket_no", this.ticket_id)
    formdata.append("vendor_no", this.vendor_no)
    formdata.append('reply_on_ticket_summary_id',this.Ticket_reply_on_ticket_summary_id)
    formdata.append("message_detail", this.reply_message_detail)
    formdata.append("message_by", this.sessionManageMent.getEmail)
    formdata.append("is_admin", this.is_admin)

    this.webApiHttp.PostFormData(this.webApiHttp.ApiURLArray.ReplyOnSummary,formdata).then(res=>{
      if(res[0].condition.toLowerCase() == 'true')
      {
        this.getSummary();
        this._toster.success(res[0].message,'Success')
        this.message_detail=''
        this.pic_urlArray=[]
        this.fileurl = []
        this.filesArray=[]
        this.ReplyDia.close()
        this.reply_message_detail=''
      }
      else
      {
        this._toster.error(res[0].message,'Error')
      }
    }).catch(err=>{
      console.log(err)
    }).finally(()=>{
      this.spinner.hide()
    })
  }

  CloseDialogReply()
  {
    this.ReplyDia.close()
  }


}
