import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';
import {PristineModule} from '@pristinebase/pristine.module';
import {PristineSharedModule} from '@pristinebase/shared.module';
import {PristineProgressBarModule, PristineSidebarModule, PristineThemeOptionsModule} from '@pristinebase/components';
import {pristineConfig} from 'app/pristine-config';
import {AppComponent} from 'app/app.component';
import {AppStoreModule} from 'app/store/store.module';
import {LayoutModule} from 'app/layout/layout.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {NgxSpinnerModule} from "ngx-spinner";
import {PreviewFileComponent} from "../@pristinebase/components/previewFile/previewFile.component";
import {TicketSummaryMailComponent} from "./main/RaiseQuerry/ticket-summary-mail/ticket-summary-mail.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {Login2Service} from "./main/pages/authentication/login-2/login-2.service";
// import {QuillModule} from "ngx-quill";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatMenuModule} from "@angular/material/menu";
import {MatCardModule} from "@angular/material/card";
import {MatDividerModule} from "@angular/material/divider";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";


const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    },
    {
        path: 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'setup',
        loadChildren: () => import('./main/Setup/Setup.module').then(m => m.SetupModule)
    },
    {
        path: 'purchase',
        loadChildren: () => import('./main/purchase/purchase.module').then(m => m.PurchaseModule)
    },
    {
        path: 'vendor',
        loadChildren: () => import('./main/vendor/vendor.module').then(m => m.VendorModule)
    },
    {
        path: 'about',
        loadChildren: () => import('./main/about/about.module').then(m => m.AboutModule)
    },
    {
        path: 'packing',
        loadChildren: () => import('./main/packing/packing.module').then(m => m.PackingModule)
    },
    {
        path: 'user',
        loadChildren: () => import('./main/user/user.module').then(m => m.UserModule)
    },
    {
        path: 'itemMaster',
        loadChildren: () => import('./main/item-master/item-master.module').then(m => m.ItemMasterModule)
    },
    {
        path: 'purchase_credit',
        loadChildren: () => import('./main/PurchaseCredit/purchase-credit.module').then(m=>m.PurchaseCreditModule)
    },
    {
        path: 'bank_account_ledger',
        loadChildren: () => import('./main/BankLedger/bank.module').then(m=>m.BankModule)
    },
    {
        path: 'raise_query',
        loadChildren: () => import('./main/RaiseQuerry/querry.module').then(m=>m.QuerryModule)
    },
    {
        path: 'raise_query_admin',
        loadChildren: () => import('./main/AdminQuery/admin-query.module').then(m=>m.AdminQueryModule)
    },
    {
      path: 'transfer_order',
      loadChildren: () => import('./main/PurchaseReturn/purchase-return.module').then(m => m.PurchaseReturnModule)
    },
    {
      path: 'TicketSummaryMail/:ticket_id',
      component:TicketSummaryMailComponent
    },
    {
        path: '**',
        redirectTo: 'apps/dashboards/VendorDashboard'
    }


];

@NgModule({
    declarations: [
        AppComponent,
        PreviewFileComponent,
      TicketSummaryMailComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // QuillModule.forRoot(),
    RouterModule.forRoot(appRoutes, {useHash: true}),
    TranslateModule.forRoot(),
    // Material moment date module
    MatMomentDateModule,
    NgxSpinnerModule,
    // Material
    MatButtonModule,
    MatIconModule,
    // Pristine modules
    PristineModule.forRoot(pristineConfig),
    PristineProgressBarModule,
    PristineSharedModule,
    PristineSidebarModule,
    PristineThemeOptionsModule,
    // App modules
    LayoutModule,
    AppStoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatCardModule,
    MatDividerModule,
    MatBottomSheetModule
  ],
    bootstrap: [
        AppComponent
    ],
    entryComponents:[PreviewFileComponent],
    providers: [
      Login2Service,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
    ],
})
export class AppModule {
}

