import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {SessionManageMent} from "./SessionManageMent";

@Injectable({
    providedIn: 'root'
})
export class WebApiHttp implements OnInit {

    // public globalurl: string = 'https://zivamehhd.pristinefulfil.com';
    public globalurl: string = 'https://beautyconceptvendorpanel.pristinefulfil.com';
    //public globalurl: string = 'https://localhost:5001';

    private apiURLArray = {

        login: '/api/UserLogin/Login',
        forgetpassword: '/api/UserLogin/ForgotPassword',
        Logout: '/api/UserLogin/Logout',
        createUser: '/api/UserLogin/CreateUser',
        getAllUser: '/api/UserLogin/getAllUser',
        roleProcess: '/api/Role/RoleProcess',
        signalRNotification: '/Notification',

        //vendor
        getAllVendorList: '/api/Vendor/getAllVendorList',
        getVendorDetail: '/api/Vendor/getVendorDetail?vendorNo=',
        updateVendorTolerance: '/api/Vendor/updateVendorTolerance',
        vendorUpdate: '/api/Vendor/vendorUpdate',
        VendorItemModel:'/api/ItemVendor/VendorItemQtyReportGetModel',

        //ItemMaster
        ItemMstVendorGet:'/api/ItemVendor/ItemMstVendorGet',
        ItemMstVendorGetItemCode:'/api/ItemVendor/ItemMstVendorGetItemCode',
        ItemMstVendorApproval:'/api/ItemVendor/ItemMstVendorApproval',

      //transfer Order
        GetTransferOrderHeaderList:'/api/TransferOrder/GetTransferOrderHeaderList',
        sale_order_id:'/api/TransferOrder/TransferOrderGet/sale_order_id?sale_order_id=',
        InvoiceTransferHeaderGet:'/api/TransferOrder/InvoiceTransferHeaderGet',
        InvoicedTransferReportGet:'/api/TransferOrder/InvoicedTransferReportGet',

        //purchaseOrder
        getPoHeader: '/api/PurchaseOrder/getPoHeader?vendor_code=',
        getPoHeaderCompleted: '/api/PurchaseOrder/getPoHeaderPending_and_Completed?vendor_code=',
        getPoLine: '/api/PurchaseOrder/getPoLine?purchase_order_no=',
        PurchaseHeaderLineGet: '/api/PurchaseOrder/PurchaseHeaderLineGet?purchase_order_no=',
        getAllPoHeader: '/api/PurchaseOrder/getAllPoHeader',
        purchaseOrderBarcodes: '/api/PurchaseOrder/purchaseOrderBarcodes',
        poLinesRePrintBarcodes: '/api/PurchaseOrder/poLinesRePrintBarcodes',
        getPurchaseOrderLog: '/api/PurchaseOrder/getPurchaseOrderLog',
        getPurchaseOrderAdminLog: '/api/PurchaseOrder/getPurchaseOrderAdminLog',
        getPurchaseOrderReportLog: '/api/PurchaseOrder/getPurchaseOrderReportLog?vendor_code=',
        purchaseOrderReportDownload: '/api/PurchaseOrder/purchaseOrderReportDownload',
        purchaseOrderDownloadErrorReport: '/api/PurchaseOrder/purchaseOrderDownloadErrorReport',
        confirmPO: '/api/PurchaseOrder/PO_Confirmation?purchase_order_no=',
        completePO: '/api/PurchaseOrder/purchase_order_complete?purchase_order_no=',
        getAdminPoList:'/api/PurchaseOrder/getAllPoHeader',
        updatePOTime:'/api/PurchaseOrder/POArrivalUpdate',

        PurchaseLineDownload: '/api/PurchaseOrder/PurchaseLineDownload?purchase_order_no=',
        PurchaseLineUploadActual: '/api/PurchaseOrder/PurchaseLineUploadActual',
        PurchaseLineUploadUpdate: '/api/PurchaseOrder/PurchaseLineUploadUpdate',

        PurchaseOrderSerialReport: '/api/PurchaseOrder/SerialReport?vendor_code=',
        PurchaseOrderBoxReport: '/api/PurchaseOrder/BoxReport?vendor_code=',

        //packingList
        getPackingList: '/api/PackingList/getPackingList',
        packing_list_upload: '/api/PackingList/packing_list_upload',
        itemcodeBarcodeListUpload: '/api/PackingList/itemcodeBarcodeListUpload',
        itemcodeBarcodeListDetails: '/api/PackingList/itemcodeBarcodeListDetails',
        packinglist:'/api/PackingList/packing_list_PO_get?vendor_code=',
        insertPackingList:'/api/PackingList/packing_list_insert',
        packingListHeader:'/api/PackingList/PackingListHeaderGet',
        packingLineGet:'/api/PackingList/PackingListLineGet',

        InvoiceReport_TO:'/api/PackingList/InvoiceReport_TO?flag=',
        DCSingleReportDownload:'/api/PackingList/DCSingleReportDownload_url?order_no=',


          PackingImageUpload:'/api/PackingList/PackingImageUpload',
          PackinImageDelete:'/api/PackingList/PackinImageDelete',

           PackingReport : '/api/PackingList/PackingReport',
        // getPoHeaderPending_and_Completed:'/api/PurchaseOrder/getPoHeaderPending_and_Completed',


        // dashboard
        vendorDashboardData : '/api/Dashboard/vendorDashboardData?vendor_code=',
        getVendorActivityLog : '/api/Dashboard/getVendorActivityLog?vendor_code=',

        DashboardData: '/api/OutBoundDashboard/dashboard_Data?order_type=',
        WaveWiseZoneActivity: '/api/OutBoundDashboard/WaveWiseZoneActivity?emailid=',
        PendingReport: '/api/Report/PendingInfo',
        PendingInfoWithData: '/api/Report/PendingInfoWithData',


        PODistributionData: '/api/PurchaseOrder/PODistributionData',
        PODistributionInsert: '/api/PurchaseOrder/PODistributionInsert',
        POLineBoxPackingGet: '/api/PurchaseOrder/POLineBoxPackingGet',
        PODistributionDeleteLine: '/api/PurchaseOrder/PODistributionDeleteLine',
        POLineBoxPackingCreate: '/api/PurchaseOrder/POLineBoxPackingCreate',


        PackingListCancelled: '/api/PackingList/PackingListCancelled',

        PO_BoxPAcking: '/api/PurchaseOrder/purchase_order_BoxReport?purchase_order_line_distribution_id=',

        //VENDOR ITEM UPLOADER
        itemVendorCreate:'/api/ItemVendor/ItemVendorCreate',

        PurchaseEwayImageUpload:'/api/ItemVendor/PurchaseEwayImageUpload',
        PurchaseEwayImageDelete:'/api/ItemVendor/PurchaseEwayImageDelete',

        PurchaseImageUpload:'/api/ItemVendor/PurchaseImageUpload',
        PurchaseImageDelete:'/api/ItemVendor/PurchaseImageDelete',

    //  Purchase Credit Memo

      getPurchaseMemoData:'/api/PurchaseCreditMemo/get_purchase_credit_memo_header_from_vendor?vendor_no=',
      getPurchaseLineVendor:'/api/PurchaseCreditMemo/get_purchase_credit_memo_line_from_vendor?document_no=',

        // Bank account paymeny

        getLedgerVendor:'/api/BankAccountPayment/get_bank_ledger_payment_from_vendor?vendor_no=',
        getLedgerDocument:'/api/BankAccountPayment/get_bank_ledger_payment_from_document?document_no=',

    //  Query Api
      ticketQueryGet:'/api/TicketQuery/TicketQueryGet',
      // SendQuery:'/api/TicketQuery/TicketQueryInsert',
      SendQuery:'/api/TicketQuery/TicketQueryInsert',
      UpdateQueryTicket:'/api/TicketQuery/TicketQueryUpdate',
      // DeleteTicketImage:'​/api​/TicketQuery​/TicketImageDelete',
      DeleteTicketImage:'/api/TicketQuery/TicketImageDelete',
      ticketQuerySummary:'/api/TicketQuery/TicketQuerySummaryGetByTicketNo',
      InsertSummaryTicket:'/api/TicketQuery/TicketQuerySummaryInsert',
      UpdateSummaryTicket:'/api/TicketQuery/TicketQuerySummaryUpdate',
      ReplyOnSummary:'/api/TicketQuery/TicketQuerySummaryInsert',
      ClosedTicket:'/api/TicketQuery/TicketQueryClose',
      reopenTicket:'/api/TicketQuery/TicketQueryReOpen',
      GetClosedQuery:'/api/TicketQuery/TicketQueryGetAll',
      GetAllOpenAdminQuery:'/api/TicketQuery/TicketQueryGetAllOpenAdmin',
      GetAllClosedAdminQuery:'/api/TicketQuery/TicketQueryGetAllClosedAdmin',

    };
    public ApiURLArray: any = this.apiURLArray;

    constructor(private httpClient: HttpClient,
                private sessionManageMent:SessionManageMent,
    ) {

    }

    get getHTTPHeader(): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    ngOnInit(): void {

    }

    getHTTPHeaderAuth(token: string): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
    }

    // post data to server
    async Post(path: string, jsondata: any): Promise<any> {
        try {
            path = this.globalurl + path;

            var heasd: HttpHeaders = new HttpHeaders({
                'Content-Type': 'application/json',
            })

            var headers = this.getHTTPHeader;
            return await new Promise<any>((resolve, reject) => {
                this.httpClient.post<any>(path, JSON.stringify(jsondata), headers).toPromise().then(result => resolve(result), error => reject({
                    condition: 'False',
                    message: error.message
                })).catch(err => reject({condition: 'False', message: err.message}));
            });
        } catch (e) {
            return new Promise<any>((resolve) => {
                resolve({condition: 'False', message: e.message})
            });
        }
    }

    // get data to the server
    async Get(path: string): Promise<any> {
        try {
            path = this.globalurl + path;
            var headers = this.getHTTPHeader;
            return await new Promise<any>((resolve, reject) => {
                this.httpClient.get<any>(path, headers).toPromise().then(result => resolve(result), error => reject({
                    condition: 'False',
                    message: error.message
                })).catch(err => reject({condition: 'False', message: err.message}));
            });
        } catch (e) {
            return new Promise<any>((resolve) => {
                resolve({condition: 'False', message: e.message})
            });
        }
    }

    // post data to server and get two type of response
    Post_Data_GetFile(path: string, jsondata: any) {
        path = this.globalurl + path;
        const request = new HttpRequest('POST', path, jsondata, {
            responseType: 'blob',
            reportProgress: true,
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
        return this.httpClient.request(request);

    }

    Get_Data_With_DownloadStatus_GetFile(path: string) {
        path = this.globalurl + path;
        const request = new HttpRequest('GET', path, {
            responseType: 'blob',
            reportProgress: true,
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
        return this.httpClient.request(request);
    }

  Get_Data_With_DownloadStatus_GetFile_1(path: string) {
    const request = new HttpRequest('GET', path, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('Authorization',this.sessionManageMent.getAuthToken)
    });
    return this.httpClient.request(request);
  }

  Get_Data_With_DownloadStatus_GetFile_binary(path: string) {
    const request = new HttpRequest('GET', path, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'binary/octet-stream').append('Authorization',this.sessionManageMent.getAuthToken)
    });
    return this.httpClient.request(request);
  }
  Get_Data_With_DownloadStatus_GetFile_binary1(path: string) {
      let temp_path = this.globalurl + path;
    const request = new HttpRequest('GET', temp_path, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'binary/octet-stream').append('Authorization',this.sessionManageMent.getAuthToken)
    });
    return this.httpClient.request(request);
  }

    Post_Data_With_DownloadStatus_GetFile(path: string,jsondata : any) {
        path = this.globalurl + path;
        const request = new HttpRequest('POST', path, jsondata, {
            responseType: 'blob',
            reportProgress: true,
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
        return this.httpClient.request(request);
    }


    // For formdata
    async PostFormData(path: string, formdata: any): Promise<any> {
        try {
            path = this.globalurl + path;
            return await new Promise<any>((resolve, reject) => {
                this.httpClient.post<any>(path, formdata).toPromise()
                    .then(result => resolve(result), error => reject({
                        condition: 'false',
                        message: error.message
                    })).catch(error => reject({
                    condition: 'false',
                    message: error.message
                }))
            })

        } catch (e) {
            return new Promise<any>((resolve) => {
                resolve({condition: 'false', message: e.message})
            })
        }
    }

    blobToString(b) {
        var urldata, x;
        urldata = URL.createObjectURL(b);
        x = new XMLHttpRequest();
        x.open('GET', urldata, false); // although sync, you're not fetching over internet
        x.send();
        URL.revokeObjectURL(urldata);
        return x.responseText;
    }
}
